import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ForSeo from "../Components/seo/ForSeo";
import { getRequest } from "../Helpers/APIHelper";
import useLocalStorage from "../hooks/useLocalStorage";
import yes from "../Assets/Images/yes.png";
import { FaFileDownload } from "react-icons/fa";
import PaxIcon from "../Assets/Images/pax-icon.png";
import LugIcon from "../Assets/Images/lug-icon.png";
import SeatIcon from "../Assets/Images/child-seat-icon.png";
import axios from "axios";
import { config } from "../Helpers/Constants";
const apiUrl = config.API_URL;

const ThankYou = (props) => {
  const navigate = useNavigate();
  const { getItem } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const booking_ref = JSON.parse(searchParams.get("booking_ref"));
  const return_booking_ref = JSON.parse(searchParams.get("return_booking_ref"));
  const paymentStatus = searchParams.get("payment");
  const oneWay_bookingData = getItem("one_way");
  const twoWay_bookingData = getItem("two_way");
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(false);

  // -------for-seo-----
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  const currentURL = window.location.pathname;

  useEffect(() => {
    getSeo();
  }, []);

  useEffect(() => {
    if (!getItem("fare_data")) {
      navigate("/");
    }
  }, [getItem("fare_data")]);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };

  useEffect(() => {
    setMessage(localStorage.getItem("thankYouMsg"));
    localStorage.removeItem("thankYouMsg");
  }, []);

  // const handleVoucherDownload = async (e) => {
  //   e.preventDefault();
  //   setIsInvoiceDownloading(true);
  //   try {
  //     const response = await getRequest(
  //       `quote/download-statement?booking_id=${booking_ref}`,
  //       {
  //         responseType: "blob",
  //       }
  //     );

  //     console.log(response);
  //     setIsInvoiceDownloading(false);
  //     const blob = new Blob([response], { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     window.open(url, "_blank");
  //   } catch (error) {
  //     setIsInvoiceDownloading(false);
  //   }
  // };

  const handleVoucherDownload = async (e) => {
    e.preventDefault();
    setIsInvoiceDownloading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${apiUrl}quote/download-statement?booking_id=${booking_ref}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsInvoiceDownloading(false);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the voucher:", error);
      setIsInvoiceDownloading(false);
    }
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? props?.title}
        description={seo?.description ?? props?.desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={canonicalURL ?? ""}
      />
      <div className="thankyou-banner section-gap inner-banner">
        <Container>
          <div>
            <h1>
              Your Booking #{booking_ref}
              {return_booking_ref && `   and #${return_booking_ref}`} request
              has submitted.
            </h1>
            {paymentStatus ? (
              <p>
                Your booking has been received, and our team will confirm it via
                email. However, the payment was declined. Thank you!
              </p>
            ) : (
              <p>{message}</p>
            )}
            <div
              style={{ display: "flex", gap: "20px", justifyContent: "center" }}
            >
              <button
                onClick={handleVoucherDownload}
                disabled={isInvoiceDownloading}
                style={{
                  width: "300px",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#ec6408",
                  opacity: isInvoiceDownloading ? 0.6 : 1,
                }}
                className="custom-btn-1"
                variant="main"
                type="submit"
              >
                <FaFileDownload />
                {isInvoiceDownloading ? "Downloading..." : "Download Voucher"}
                {/* Download Voucher */}
              </button>
              <button
                onClick={() => navigate("/")}
                style={{
                  width: "300px",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="custom-btn"
                variant="main"
                type="submit"
              >
                Make New Booking
                {/* Download Voucher */}
              </button>
            </div>

            <div class="booking-form-wrapper section-gap">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="booking-form">
                      <Form>
                        <Row>
                          <Col lg={12}>
                            <h4
                              style={{
                                fontSize: "20px",
                                fontWeight: "bolder",
                                width: "100%",
                              }}
                              className="mt-0"
                            >
                              {" "}
                              Thank you for Booking
                            </h4>
                            <div className="divider"></div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "20px 0px",
                                borderBottom: "2px dotted #7d7d7d",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: "left",
                                }}
                              >
                                <p
                                  style={{ margin: "0px", textAlign: "start" }}
                                >
                                  <span> Full Name</span>:{" "}
                                  {oneWay_bookingData?.name}
                                </p>
                                <p
                                  style={{ margin: "0px", textAlign: "start" }}
                                >
                                  <span>Email</span> :{" "}
                                  {oneWay_bookingData?.email}
                                </p>
                                <p
                                  style={{ margin: "0px", textAlign: "start" }}
                                >
                                  <span> Contact Number </span> :{" "}
                                  {oneWay_bookingData?.mobile}
                                </p>
                              </div>

                              {oneWay_bookingData?.lead_passenger_name && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <h4
                                    style={{
                                      margin: "0px",
                                    }}
                                  >
                                    Booking For Someone Else
                                  </h4>
                                  <p
                                    style={{
                                      margin: "0px",
                                      textAlign: "start",
                                    }}
                                  >
                                    <span> Lead Passenger Name</span>:{" "}
                                    {oneWay_bookingData?.lead_passenger_name}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0px",
                                      textAlign: "start",
                                    }}
                                  >
                                    <span>Lead Passenger Number</span> :{" "}
                                    {oneWay_bookingData?.lead_passenger_phone}
                                  </p>
                                </div>
                              )}
                            </div>
                          </Col>

                          <Col style={{ padding: "10px" }} md={12}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                              }}
                            >
                              <h4 style={{ fontWeight: "600", margin: "0px" }}>
                                One Way
                              </h4>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "10px 0px",
                                }}
                              >
                                <div>
                                  <p
                                    style={{ margin: "0px", fontWeight: "500" }}
                                  >
                                    pickup Address
                                  </p>
                                  <p>{oneWay_bookingData?.from_location}</p>
                                </div>
                                <div>
                                  <p
                                    style={{ margin: "0px", fontWeight: "500" }}
                                  >
                                    pickup Date
                                  </p>
                                  <p style={{ margin: "0px" }}>
                                    {oneWay_bookingData?.pickup_Date}
                                  </p>
                                  <p>{oneWay_bookingData?.pickup_time}</p>
                                </div>
                                <div>
                                  <p
                                    style={{ margin: "0px", fontWeight: "500" }}
                                  >
                                    Dropoff Address
                                  </p>
                                  <p>{oneWay_bookingData?.to_location}</p>
                                </div>
                              </div>
                            </div>

                            {twoWay_bookingData && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: "left",
                                }}
                              >
                                <h4
                                  style={{ fontWeight: "600", margin: "0px" }}
                                >
                                  Two Way
                                </h4>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "10px 0px",
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      pickup Address
                                    </p>
                                    <p>{twoWay_bookingData?.from_location}</p>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      pickup Date
                                    </p>
                                    <p style={{ margin: "0px" }}>
                                      {twoWay_bookingData?.pickup_Date}
                                    </p>
                                    <p>{twoWay_bookingData?.pickup_time}</p>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Dropoff Address
                                    </p>
                                    <p>{twoWay_bookingData?.to_location}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Col>

                          <Row style={{ padding: "30px 30px" }}>
                            <Col lg={6} md={6}>
                              <h4>Capacity & Extras</h4>
                              {true && (
                                <div>
                                  <div>
                                    <img
                                      title="thank"
                                      loading="lazy"
                                      style={{
                                        width: "100px",
                                        height: "50px",
                                        margin: "0px",
                                      }}
                                      alt=""
                                      src={oneWay_bookingData?.fleet_image}
                                    />
                                    <p style={{ margin: "0px" }}>
                                      {oneWay_bookingData?.fleet_name}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        title="thank"
                                        loading="lazy"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={PaxIcon}
                                        alt="passenger_icon"
                                      />{" "}
                                      {oneWay_bookingData?.passenger_count}
                                    </span>
                                    <span>
                                      <img
                                        title="thank"
                                        loading="lazy"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={LugIcon}
                                        alt="passenger_icon"
                                      />{" "}
                                      {oneWay_bookingData?.luggage_count}
                                    </span>
                                    <span>
                                      <img
                                        title="thank"
                                        loading="lazy"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={SeatIcon}
                                        alt="passenger_icon"
                                      />{" "}
                                      {oneWay_bookingData?.baby_seat_count}
                                    </span>
                                  </div>
                                  <div></div>
                                </div>
                              )}
                            </Col>
                            {oneWay_bookingData?.special_instruction && (
                              <Col lg={6} md={6}>
                                <h4>Note To Driver</h4>
                                <p style={{ textAlign: "start" }}>
                                  {oneWay_bookingData?.special_instruction}
                                </p>
                              </Col>
                            )}
                          </Row>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {oneWay_bookingData?.flight_number && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: "left",
                                }}
                              >
                                <h4 style={{ fontWeight: "600" }}>
                                  Flight Information
                                </h4>
                                <p
                                  style={{ margin: "0px", textAlign: "start" }}
                                >
                                  <span> Airlines</span>:{" "}
                                  {oneWay_bookingData?.airlines}
                                </p>
                                <p
                                  style={{ margin: "0px", textAlign: "start" }}
                                >
                                  <span>Flight Name</span> :{" "}
                                  {oneWay_bookingData?.flight_name}
                                </p>
                                <p
                                  style={{ margin: "0px", textAlign: "start" }}
                                >
                                  <span> Flight Number</span> :{" "}
                                  {oneWay_bookingData?.flight_number}
                                </p>
                              </div>
                            )}
                          </div>

                          <Col lg={12}>
                            <h4 style={{ fontWeight: "600" }}>Fare Info</h4>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0px",
                                margin: "0px",
                              }}
                            >
                              <p>One way Fare:</p>

                              <p>
                                <strong>
                                  {oneWay_bookingData?.currency}{" "}
                                  {oneWay_bookingData?.total_fare}{" "}
                                </strong>
                              </p>
                            </div>

                            {twoWay_bookingData && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p>Two way Fare:</p>

                                <p>
                                  <strong>
                                    {twoWay_bookingData?.total_fare}
                                  </strong>
                                </p>
                              </div>
                            )}
                          </Col>
                          <div className="divider"></div>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {/* {currentURL === "/iframe/thank-you" ? (
              <Link
                onClick={() => localStorage.removeItem("quote")}
                to="/iframe"
                className="btn-sm btn-main"
              >
                Go to Homepage
              </Link>
            ) : (
              <Link
                onClick={() => localStorage.removeItem("quote")}
                to="/"
                className="btn-sm btn-main"
              >
                Go to Homepage
              </Link>
            )} */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ThankYou;
