import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { postRequest } from "../../Helpers/APIHelper";
import ReCAPTCHA from "react-google-recaptcha";
import { useDataContext } from "../../context/dataContext";
import FormatPhoneNumber from "../../Helpers/FormatPhoneNumber";
import { MdOutlinePayment } from "react-icons/md";
import { MdCreditCardOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

const PaymentPopup = ({ open, handleClose, errors }) => {
  const navigate = useNavigate();
  const { removeItem } = useLocalStorage();
  const { profileData } = useDataContext();

  return (
    <>
      <Modal
        size="lg"
        show={open}
        className="payment-modal error-centered-modal centered-modal"
      >
        <Modal.Body>
          <div style={{ maxWidth: "500px", padding: "20px" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <h5>Payment Decline</h5>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="icon-ctn">
                  <MdCreditCardOff style={{ color: "#ff0000b3" }} size={40} />
                </div>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "",
                  wordBreak: "break-word",
                }}
              >
                {errors.message}
              </p>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => {
                  removeItem("quote");
                  navigate(
                    `/thank-you?booking_ref=${errors?.booking_id}&payment=declined`
                  );
                }}
                style={{ width: "100%" }}
                variant="outline-success"
              >
                Pay Later
              </Button>
              <button
                onClick={handleClose}
                className="custom-btn"
                variant="main"
                type="submit"
              >
                Try Again
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentPopup;
