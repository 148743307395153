import { useCallback } from "react";

const useLocalStorage = () => {
  const addItem = useCallback((key, value) => {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, value);
    }
  }, []);

  const removeItem = useCallback((key) => {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  }, []);

  const getItem = useCallback((key) => {
    if (typeof window !== "undefined") {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
    return null;
  }, []);

  return { addItem, removeItem, getItem };
};

export default useLocalStorage;
