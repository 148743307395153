import React, { Component } from "react";

class Map extends Component {
  componentDidMount() {
    const { origin, destination, waypoints } = this.props;
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: { lat: origin.lat, lng: origin.lng },
      disableDefaultUI: true,
    });

    directionsRenderer.setMap(map);

    const waypointLocations = waypoints.map((point) => ({
      location: new window.google.maps.LatLng(point.lat, point.lng),
      stopover: true,
    }));

    if (destination.lat === 0 && destination.lng === 0) {
      new window.google.maps.Marker({
        position: { lat: origin.lat, lng: origin.lng },
        map: map,
        label: "A",
      });
    } else {
      const request = {
        origin: new window.google.maps.LatLng(origin.lat, origin.lng),
        destination: new window.google.maps.LatLng(
          destination.lat,
          destination.lng
        ),
        travelMode: "DRIVING",
        waypoints: waypointLocations,
      };

      directionsService.route(request, (result, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(result);
        }
      });
    }
  }

  render() {
    return <div id="map" style={{ width: "100%", minHeight: "500px" }}></div>;
  }
}

export default Map;
