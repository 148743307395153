import React, { useEffect } from "react";
import { useDataContext } from "../context/dataContext";

const GoogleAnalytics = () => {
  const { profileData } = useDataContext();
  const gtag = profileData?.google_analytics;

  useEffect(() => {
    if (gtag) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtag}`;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;

        gtag("js", new Date());
        gtag("config", gtag);
      };
    }
  }, [gtag]);

  return null;
};

export default GoogleAnalytics;
