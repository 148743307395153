import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";
import PlotMap from "./PlotMap";
import { postRequest } from "../Helpers/APIHelper";
import LoadingSpinner from "./LoadingSpinner";
import { showToast } from "./ShowToast";

const GetInTouch = ({ setting }) => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("quote/contact", formData)
      .then((response) => {
        setIsLoading(false);
        setBtnDisable(false);
        showToast(response?.message, "success");
        setFormData({});
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error?.message, "error");
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <div className="get-touch-wrapper">
        <Container>
          <Row>
            <Col lg={6}>
              {setting?.country_map?.lat && (
                <PlotMap
                  origin={{
                    lat: setting.country_map.lat,
                    lng: setting.country_map.lng,
                  }}
                />
              )}
            </Col>
            <Col lg={6}>
              <div className="touch-form">
                <h4>Get In Touch</h4>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="fname"
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          name="lname"
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Telephone"
                          name="phone"
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          placeholder="Your Message Here"
                          rows="8"
                          name="message"
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <ReCAPTCHA sitekey="6Ldoys0iAAAAAKMK-xJT3IntXQFFcIgWotiVhaKG" onChange={handleCaptchaChange} /> */}
                  <ReCAPTCHA
                    sitekey="6LfuSaEmAAAAAA5iGpXLUT44a1ta1xCj_3upMlcQ"
                    onChange={handleCaptchaChange}
                  />

                  <Button
                    disabled={isLoading}
                    type="submit"
                    className="btn-main"
                    variant="main"
                  >
                    <span
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {" "}
                      {isLoading && <LoadingSpinner />}
                      {"Submit"}
                    </span>
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GetInTouch;
