import moment from "moment";
import React, { useState } from "react";
import Map from "./Map";
import PaxIcon from "../Assets/Images/pax-icon.png";
import LugIcon from "../Assets/Images/lug-icon.png";
import SeatIcon from "../Assets/Images/child-seat-icon.png";

const InnerBanner = ({ quote, title, fleet }) => {
  return (
    <>
      <section className="inner-banner">
        {quote.from_lat && (
          <Map
            origin={{ lat: quote.from_lat, lng: quote.from_lng }}
            destination={{ lat: quote.to_lat, lng: quote.to_lng }}
            waypoints={quote.via}
          />
        )}
        <div className="ride-info">
          <div className="ride-main-details">
            <h1>{title}</h1>
            <div className="route-info">
              <p>{quote?.from_location ?? ""}</p>
              {quote?.via &&
                quote?.via.map((point, ind) => (
                  <p key={ind} className="via-point">
                    {point?.location}
                  </p>
                ))}
              {quote?.to_location && <p>{quote?.to_location ?? ""}</p>}
            </div>
            {quote?.service_type == "point_to_point" ? (
              <p className="distance-duration">
                <span>
                  Est. Distance <strong>{quote?.distance ?? ""}</strong>
                </span>
                <span>
                  Est. Time <strong>{quote?.duration ?? ""}</strong>
                </span>
              </p>
            ) : (
              <p className="distance-duration">
                <span>
                  {" "}
                  Duration <strong>{quote?.hourly_duration ?? ""} Hr</strong>
                </span>
              </p>
            )}

            <div className="date-time-wrapper">
              <div className="date-wrap">
                {moment(quote.pickup_datetime).format("DD")}
                <span>
                  {moment(quote.pickup_datetime).format("MMM")}
                  <strong>
                    {moment(quote.pickup_datetime).format("YYYY")}
                  </strong>
                </span>
              </div>
              <div className="time-wrap">
                <strong>
                  {" "}
                  {moment(quote.pickup_datetime).format("HH:mm A")}
                </strong>
                {moment(quote.pickup_datetime).format("dddd")}
              </div>
            </div>
            {quote.return_datetime && (
              <div className="date-time-wrapper mt-2">
                <div className="date-wrap">
                  {moment(quote.return_datetime).format("DD")}
                  <span>
                    {moment(quote.return_datetime).format("MMM")}
                    <strong>
                      {moment(quote.return_datetime).format("YYYY")}
                    </strong>
                  </span>
                </div>
                <div className="time-wrap">
                  <strong>
                    {" "}
                    {moment(quote.return_datetime).format("HH:mm A")}
                  </strong>
                  {moment(quote.return_datetime).format("dddd")}
                </div>
              </div>
            )}

            {fleet && (
              <div className="selected-vehicle">
                <div className="selected-fleet-ctn">
                  <img
                    loading="lazy"
                    title="capacity"
                    src={fleet?.image_link ?? ""}
                    alt="fleets"
                  />
                </div>
                <div>
                  <h5> {fleet?.title ?? ""} </h5>
                  <div className="capacity-wrap">
                    <span>
                      <img
                        loading="lazy"
                        title="capacity"
                        src={PaxIcon}
                        alt="passenger_icon"
                      />{" "}
                      {fleet?.max_passenger ?? ""}
                    </span>
                    <span>
                      <img
                        loading="lazy"
                        title="capacity"
                        src={LugIcon}
                        alt="luggage_icon"
                      />{" "}
                      {fleet?.max_luggage ?? ""}
                    </span>
                    <span>
                      <img
                        loading="lazy"
                        title="capacity"
                        src={SeatIcon}
                        alt="seat_icon"
                      />{" "}
                      {fleet?.max_baby_seat ?? ""}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default InnerBanner;
