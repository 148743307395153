import React, { Component } from 'react';

class PlotMap extends Component {
    componentDidMount() {
        const { origin } = this.props;
        new window.google.maps.Map(document.getElementById('single-map'), {
            zoom: 4,
            center: { lat: origin?.lat ?? 37.7749, lng: origin?.lng ?? -122.4194 },
            disableDefaultUI: true
        });
    }

    render() {
        return (
            <div id="single-map" style={{ width: '100%', height: '100%' }}></div>
        );
    }
}

export default PlotMap;
