import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, redirectTo = "/" }) => {
  const isAuthorized = () => {
    const data = localStorage.getItem("quote");

    return data !== null;
  };

  return isAuthorized() ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
