import React, { useEffect, useState } from "react";
import { Col, Container, Row, TabContainer } from "react-bootstrap";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { getRequest } from "../Helpers/APIHelper";
import { Helmet } from "react-helmet";
import ForSeo from "../Components/seo/ForSeo";
import { useLocation } from "react-router-dom";

const Privacy = (props) => {
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  useEffect(() => {
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? props?.title}
        description={seo?.description ?? props?.desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={canonicalURL ?? ""}
      />
      <Container>
        <Row>
          <Col
            style={{ minHeight: "100vh" }}
            lg="12"
            className="terms-wrapper inner-banner"
          >
            <h1>{props.title}</h1>
            <div>{props.desc} </div>
            <div
              dangerouslySetInnerHTML={{
                __html: props?.privacyContent ?? "",
              }}
            ></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Privacy;
