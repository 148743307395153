import React, { Component } from "react";

class IframeMap extends Component {
  componentDidMount() {
    const { origin, destination, originAdd } = this.props;
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: {
        lat: 27.6586414,
        lng: 85.3169528,
      },
      disableDefaultUI: true,
    });

    // Set up directions renderer
    directionsRenderer.setMap(map);

    // Get current location and set it as the center of the map
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(currentLocation);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    // Define the request for directions

    // { lat: 37.7749295, lng: -122.4194155 },

    const request = {
      origin: originAdd,
      destination: { lat: 34.0522, lng: -118.2437 },
      travelMode: "DRIVING",
    };

    // Request directions
    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(result);
      }
    });
  }

  render() {
    return <div id="map" style={{ width: "100%", height: "100vh" }}></div>;
  }
}

export default IframeMap;
