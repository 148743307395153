import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DataProvider } from "./context/dataContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import { getRequest } from "./Helpers/APIHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hydrate, render } from "react-dom";

const RootComponent = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    getRequest("stripe-key")
      .then((response) => {
        setStripePromise(loadStripe(response?.data));
      })
      .catch((error) => {
        console.error("Error fetching Stripe public key:", error);
      });
  }, []);

  return (
    <DataProvider>
      <Elements stripe={stripePromise}>
        <App />
        <ToastContainer />
      </Elements>
    </DataProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);

reportWebVitals();
