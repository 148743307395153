import { useContext, useEffect, useState } from "react";
import { createContext } from "react";

const DataContext = createContext();

export function DataProvider({ children }) {
  const [profileData, setProfileData] = useState();

  const [pageData, setPageData] = useState({
    termsData: "",
    privacyData: "",
    aboutData: "",
  });

  const value = {
    profileData,
    pageData,
    setProfileData,
    setPageData,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export const useDataContext = () => useContext(DataContext);
