import parsePhoneNumberFromString from "libphonenumber-js";

const FormatPhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number);

  if (!phoneNumber) {
    throw new Error("Invalid phone number");
  }

  const countryCode = phoneNumber.countryCallingCode;
  const formattedNumber = phoneNumber.formatNational();

  return `+${countryCode} ${formattedNumber}`;
};

export default FormatPhoneNumber;
