import React, { useEffect, useState } from "react";
import CallToBook from "../Components/CallToBook";
import FleetBox from "../Components/FleetBox";
import Footer from "../Components/Footer";
import FourSectionArrow from "../Components/FourSectionArrow";
import GetInTouch from "../Components/GetInTouch";
import MainBanner from "../Components/MainBanner";
import NavBar from "../Components/NavBar";
import TwoColImgHover from "../Components/TwoColImgHover";
import { getRequest } from "../Helpers/APIHelper";
import { useDataContext } from "../context/dataContext";
import ForSeo from "../Components/seo/ForSeo";
import { useLocation } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const Index = (props) => {
  const { removeItem, getItem } = useLocalStorage();
  const [seo, setSeo] = useState({});
  const [setting, setSetting] = useState([]);
  const [fleets, setFleets] = useState([]);
  const location = useLocation();

  const canonicalURL = window?.location?.href;

  console.log();
  const { profileData } = useDataContext();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setSetting(profileData);
      getFleet();
      getSeo();
    }
  }, [profileData]);

  useEffect(() => {
    removeItem("fare_data");
    removeItem("two_way");
    removeItem("thankMsg");
  }, [getItem("fare_data")]);

  const getFleet = () => {
    getRequest("fleet-categories")
      .then((response) => {
        setFleets(response?.categories ?? []);
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  // old url ->> `header-seo-data?slug=${location.pathname.substring(1)}`

  const getSeo = async () => {
    const res = await getRequest(`header-seo-data?slug=home`);
    setSeo(res);
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title || props.title}
        description={seo?.description || props.desc}
        keywords={
          seo?.keyword ||
          "Ride Sharing, affordable rides, Book a Ride,Find a Ride, Best Ride Sharing,  "
        }
        siteUrl={canonicalURL ?? ""}
      />
      <MainBanner />
      <TwoColImgHover />
      <FourSectionArrow aboutInfo={props?.aboutContent} />
      <FleetBox fleets={fleets} />
      <GetInTouch setting={setting} />
      <CallToBook />
    </>
  );
};

export default Index;
