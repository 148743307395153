import React from "react";
import { Helmet } from "react-helmet";
import { useDataContext } from "../../context/dataContext";

const ForSeo = ({ title, keywords, description, siteUrl, canonicalUrl }) => {
  const { profileData } = useDataContext();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`${canonicalUrl}`} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <meta name="type" content="Safe for kids" />
        <meta name="Classification" content="internet" />
        <meta name="robots" content="All, Index, Follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="Distribution" content="Global" />
        <meta name="copyright" content={`${canonicalUrl}`} />
        <meta name="author" content={`${canonicalUrl}`} />

        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${siteUrl}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description ?? ""} />
        <meta
          property="og:image"
          content={`${profileData?.business_logo_path}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="logo" />

        {/* twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${siteUrl}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description ?? ""} />
        <meta
          name="twitter:image"
          content={`${profileData?.business_logo_path}`}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Helmet>
    </>
  );
};

export default ForSeo;
