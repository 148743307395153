import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { config } from "../Helpers/Constants";

const InputField = ({ go, placeholder, country, setCoordinates }) => {
  const demoDomain = config.DEMO_DOMAIN;
  const domainName = window.location.hostname;
  const [autocomplete, setAutocomplete] = useState(null);
  const originRef = useRef();

  function onLoad(param) {
    setAutocomplete(param);
  }

  function handleAutoComplete() {
    const getLat = autocomplete.getPlace().geometry.location.lat();
    const getLng = autocomplete.getPlace().geometry.location.lng();
    // const getName = autocomplete.getPlace().name;

    // let data;
    if (go === "from") {
      // data = {
      //   originRef: originRef.current.value,
      //   destinationRef: 'Boston Logan International Airport (BOS), Boston, MA, USA',
      // }
      setCoordinates({
        from_location: originRef.current.value,
        from_lat: getLat,
        from_lng: getLng,
      });
    } else if (go === "via") {
      setCoordinates({
        via_location: originRef.current.value,
        via_lat: getLat,
        via_lng: getLng,
      });
    } else if (go === "to") {
      setCoordinates({
        to_location: originRef.current.value,
        to_lat: getLat,
        to_lng: getLng,
      });
    }
  }

  return (
    <>
      {domainName === demoDomain ? (
        <Autocomplete onLoad={onLoad} onPlaceChanged={handleAutoComplete}>
          <input
            type="text"
            className="form-control br-0"
            required
            placeholder={placeholder}
            ref={originRef}
          />
        </Autocomplete>
      ) : (
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={handleAutoComplete}
          // options={{ componentRestrictions: { country: [country] } }}
        >
          <input
            type="text"
            required
            className="form-control br-0"
            placeholder={placeholder}
            ref={originRef}
          />
        </Autocomplete>
      )}
    </>
  );
};

export default InputField;
