import "bootstrap/dist/css/bootstrap.min.css";
import Index from "./Pages/Index";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./Assets/css/main.css";
import VehicleSelect from "./Pages/VehicleSelect";
import BookForm from "./Pages/BookForm";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/privacy";
import ThankYou from "./Pages/ThankYou";
import { lazy, useEffect, useState } from "react";
import { postRequest, getRequest } from "./Helpers/APIHelper";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import { useDataContext } from "./context/dataContext";
import NotFound from "./Pages/not-found/NotFound";
import Iframe from "./Pages/iframe/Iframe";
import whatsapp from "../src/Assets/Images/whatsapp_logo_green.png";
import GoogleAnalytics from "./Components/GoogleAnalytics";
import PaymentConfirmation from "./Pages/PaymentConfirmation";
import ProtectedRoute from "./Components/ProtectedRoute";
import NewThankYou from "./Pages/thank you/NewThankYou";
import WhatsappIcon from "../src/Assets/Images/whatsapp1.png";

// Scroll to top on page navigation
function WindowScrollTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function App() {
  const location = window.location.pathname;

  const { setProfileData, profileData } = useDataContext();
  const [loader, setLoader] = useState(true);
  const [setting, setSetting] = useState([]);

  //---for terms and privacy-policy
  const [termsContent, setTermsContent] = useState();
  const [privacyContent, setPrivacyContent] = useState();
  const [profileInfo, setProfileInfo] = useState();
  const [aboutContent, setAboutContent] = useState();

  useEffect(() => {
    const domainName = window.location.hostname;
    // const domainName = "urridebooking.demo2project.com";
    if (!localStorage.getItem("token")) {
      // localStorage.setItem(
      //   "token",
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2RiMjNjNjZhZGU3MjI0MjI2ODRlYjYwYTg3OTA3MDQzNzliYTA5ZGY3ZWRkYTVmYzMxYTMzMjBlZWE0ZDVlM2U1NjQzZDRiMDllMzA4ZGMiLCJpYXQiOjE3MjIzMjA3MjAuNTM3Mjc2LCJuYmYiOjE3MjIzMjA3MjAuNTM3Mjc5LCJleHAiOjE3NTM4NTY3MjAuNTI5MTgzLCJzdWIiOiIxNCIsInNjb3BlcyI6W119.J6G25s8bpOgJSgdQcX12IRt4GRN_Mhb8ibnp-lt9pEFw_fR6lzIBDJdu8MKaP7qgvW1rqF2y_Hn5E1TCCToiijDqY-uVqqvluGTYxc_iWYmqWOKX-cMJJJijiLcquPzhEruoZDzuh3GlcQRZSsUEZORI8Nh0zY4CdldrP2oyncjMnho8hfAExWMTs50ck9J4VrF4iNkplgi3XJja_mjgZUzkBSeWWF1g5vDaVg4Vq03A-VRi_5rcJRo06zuhFeQcKBD2citCMwGBw3byG9ouO-Ts2IPtXFarO4mDzpxRNaqdiRA9DYTeYE8UnYfg3H0-ZsOO4AIEl2ZSZRxcUZ52BHxzLC535xihYSm0cTx6eG662Z1LwqWMLMQnaPr2WbxoEegJQShFSV0C6JaX-gNTt_Qqfl35Alx0KWCTkfnouzfizg5YbKO6WO7l4mf0EEyrSa2qkeN91RN4ONPmHodsEnqGrOoSqH4m5zwx9v2EzUy1oiRCLDQagkGTxDwNLwf6gvPB2JiVltcdssyCZbt4yci64xMP5rDqZmqGmTsWeGc8aY9FxZLKIvH-pPpwmXD8JmEbdk9AGZt8oO0Kr1csr6tJ1BCNzj8BdseBcbNxULwk1b3lWCoVNR3I545tZwlFkAuA1RyJdzXjyAG8xkpdgylKTxud-lbt4msArMYooJ8"
      // );
      // setLoader(false);
      // return;

      postRequest("site-token", { domain: domainName })
        .then((response) => {
          if (response.status) {
            localStorage.setItem("token", response?.data?.token ?? "");
            setLoader(false);
            window.location.reload(false);
          } else {
            if (response?.message ?? false) {
              alert(response?.message ?? "");
              window.location.href = "https://accounts.urride.online/register/";
            }
          }
        })
        .catch((error) => {
          localStorage.remoteItem("token");
          console.error("Error:", error ?? "-");
          alert("No subscription has been requested.");
          window.location.href = "https://accounts.urride.online/register/";
        });
    } else {
      postRequest("check-token", { token: localStorage.getItem("token") })
        .then((response) => {
          if (!response.status) {
            localStorage.removeItem("token");
            // window.location.reload();
            // window.location.href = "/";
          }
        })
        .catch((error) => {
          localStorage.remoteItem("token");
        });

      getRequest("profile")
        .then((response) => {
          console.log(response);
          if (response?.profile?.is_subscribed === false) {
            alert(response?.message ?? "No subscription has been requested.");
            window.location.href = "https://accounts.urride.online/register/";
          } else {
            setProfileInfo(response?.profile);
            setProfileData(response?.profile);
            setSetting(response?.profile);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error ?? "-");
        });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      postRequest("check-token", { token: localStorage.getItem("token") })
        .then((response) => {
          if (!response.status) {
            localStorage.removeItem("token");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    getRequest("product")
      .then((response) => {
        setTermsContent(response?.content?.terms);
        setPrivacyContent(response?.content?.privacy_policy);
        setAboutContent(response?.content?.about);
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  }, []);

  //function to set favicon
  useEffect(() => {
    const setFavicon = (url) => {
      const link = document.createElement("link");
      const oldLink = document.getElementById("dynamic-favicon");
      link.id = "dynamic-favicon";
      link.rel = "icon";
      link.href = url;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
      // document.title = profileData?.business_name || "Web Booking";
    };
    setFavicon(profileData?.business_logo_path);
  }, [profileData]);

  return (
    <>
      <GoogleAnalytics />
      {loader && (
        <div className="full-page-loader">
          <div id="wifi-loader">
            <svg className="circle-outer" viewBox="0 0 86 86">
              <circle className="back" cx="43" cy="43" r="40"></circle>
              <circle className="front" cx="43" cy="43" r="40"></circle>
              <circle className="new" cx="43" cy="43" r="40"></circle>
            </svg>
            <svg className="circle-middle" viewBox="0 0 60 60">
              <circle className="back" cx="30" cy="30" r="27"></circle>
              <circle className="front" cx="30" cy="30" r="27"></circle>
            </svg>
            <svg className="circle-inner" viewBox="0 0 34 34">
              <circle className="back" cx="17" cy="17" r="14"></circle>
              <circle className="front" cx="17" cy="17" r="14"></circle>
            </svg>
            <br />
            <div
              className="text-loader"
              data-text={
                localStorage.getItem("token")
                  ? ""
                  : "Please wait authenticating...."
              }
            ></div>
          </div>
        </div>
      )}

      <Router>
        <WindowScrollTop />

        {!location.includes("iframe") && (
          <NavBar profileInfo={profileInfo} isLoading={loader} />
        )}

        <Routes>
          <Route
            path="thank-you"
            element={
              <NewThankYou
                title="Booking Confirmation | [Company Name]"
                desc="Thank you for booking your taxi or limo service with us. Your reservation is confirmed, and we look forward to providing you with safe and reliable transportation. Contact us if you have any questions or concerns."
              />
            }
          />
          <Route
            path="privacy-policy"
            element={
              <Privacy
                privacyContent={privacyContent}
                title="Privacy Policy | [Company Name]"
                desc="Our privacy policy explains how we collect, use, and protect your personal information when you use our taxi and limo services. Trust us to keep your data safe. Contact us to learn more."
              />
            }
          />
          <Route
            path="terms-of-services"
            element={
              <Terms
                termsContent={termsContent}
                title="Terms and Conditions | [Company Name]"
                desc="Read our terms and conditions for taxi and limo services. We provide safe and reliable transportation for all your travel needs. Contact us today to learn more."
              />
            }
          />

          <Route
            path="booking-form"
            element={
              <ProtectedRoute>
                <BookForm
                  // title="Journey Details | [Company Name]"
                  // desc=": View your journey details for your upcoming taxi or limo service. From pickup time and location to destination and fare, we have all the information you need to travel with ease. Contact us if you need to make any changes to your booking"
                  setting={setting}
                />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="payment"
            element={
              <BookingPayment
                // title="Journey Details | [Company Name]"
                // desc=": View your journey details for your upcoming taxi or limo service. From pickup time and location to destination and fare, we have all the information you need to travel with ease. Contact us if you need to make any changes to your booking"
                setting={setting}
              />
            }
          /> */}
          <Route
            path="payment"
            element={
              <ProtectedRoute>
                <PaymentConfirmation
                  profileInfo={profileInfo}
                  setting={setting}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="vehicle-select"
            element={
              <ProtectedRoute>
                <VehicleSelect
                  profileInfo={profileInfo}
                  // title="Vehicle Selection | [Company Name]"
                  // desc="Browse our selection of vehicles for your taxi or limo service. From luxurious sedans to spacious vans, we have the perfect ride for your needs. Book now and travel in comfort and style."
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <Index
                aboutContent={aboutContent}
                profileInfo={profileInfo}
                title="Professional Taxi and limo service - Safe, reliable, and affordable transportation"
                desc="Looking for a professional taxi or limo service? Our company provides safe, reliable, and affordable transportation services for all your needs. Book now!"
              />
            }
          />

          {/* ------for iframe ------ */}
          <Route path="/iframe" element={<Iframe />} />
          <Route
            path="/iframe/vehicle-select"
            element={
              <VehicleSelect
                profileInfo={profileInfo}
                title="Vehicle Selection | [Company Name]"
                desc="Browse our selection of vehicles for your taxi or limo service. From luxurious sedans to spacious vans, we have the perfect ride for your needs. Book now and travel in comfort and style."
              />
            }
          />
          <Route
            path="/iframe/booking-form"
            element={
              <BookForm
                title="Journey Details | [Company Name]"
                desc=": View your journey details for your upcoming taxi or limo service. From pickup time and location to destination and fare, we have all the information you need to travel with ease. Contact us if you need to make any changes to your booking"
                setting={setting}
              />
            }
          />
          <Route
            path="/iframe/thank-you"
            element={
              <ThankYou
                title="Booking Confirmation | [Company Name]"
                desc="Thank you for booking your taxi or limo service with us. Your reservation is confirmed, and we look forward to providing you with safe and reliable transportation. Contact us if you have any questions or concerns."
              />
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {!location.includes("iframe") && <Footer />}
      </Router>

      {window.location.hostname === "demo.urride.online" ||
      window.location.hostname.includes("localhost") ||
      window.location.hostname === "127.0" ? (
        <div
          style={{
            position: "fixed",
            bottom: "80px",
            right: "10px",
            zIndex: "10",
          }}
        >
          <a
            aria-label="Chat on WhatsApp"
            rel="noreferrer"
            target="_blank"
            href="https://wa.me/+9779810101016?text=Hello%2C%20I%20would%20like%20to%20chat%20with%20you"
          >
            {" "}
            <img
              title="whatsapp-icon"
              loading="lazy"
              style={{ width: "55px", borderRadius: "100%" }}
              alt="whatsapp"
              src={WhatsappIcon}
            />
          </a>
        </div>
      ) : null}
    </>
  );
}

export default App;
