import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { postRequest } from "../../Helpers/APIHelper";
import ReCAPTCHA from "react-google-recaptcha";
import { useDataContext } from "../../context/dataContext";
import FormatPhoneNumber from "../../Helpers/FormatPhoneNumber";

const ErrorModal = ({ open, handleClose }) => {
  const { profileData } = useDataContext();
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("quote/contact", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          alert(response?.message ?? "");
          window.location.reload();
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <Modal
        size="lg"
        show={open}
        className="error-centered-modal centered-modal"
      >
        <Modal.Header>
          <Modal.Title>Quote Contact.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "15px" }}>
            To ensure the quality of service, we are unable to offer online
            bookings for this transfers .If you require an urgent transfer
            please call our contact centre on {profileData?.mobile} or email us
            at {profileData ? profileData?.email : "support@urride.online"} and
            we are happy to help.
          </p>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    onChange={handleInputChange}
                    name="fname"
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    onChange={handleInputChange}
                    name="lname"
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={handleInputChange}
                    name="email"
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Telephone"
                    onChange={handleInputChange}
                    name="phone"
                    required
                  />
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Your Message Here"
                    onChange={handleInputChange}
                    rows="4"
                    name="message"
                  />
                </Form.Group>
              </Col>
            </Row>
            <ReCAPTCHA
              sitekey="6LfuSaEmAAAAAA5iGpXLUT44a1ta1xCj_3upMlcQ"
              onChange={handleCaptchaChange}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "20px",
                justifyContent: "end",
              }}
            >
              <button onClick={handleClose} className="btn-sm btn-outline">
                Close
              </button>
              <button
                type="submit"
                style={{ background: "#00914C", color: "#fff" }}
                className="btn-sm btn-outline"
              >
                Confirm
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorModal;
